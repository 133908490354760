import React from "react";
import { setImageParameters } from "../../utils";
import Icon from "../icon";
import Link from "../link";

interface MegamenuItemProps {
  url?: string;
  imageUrl?: string;
  title?: string;
}

const MegamenuItem: React.FC<MegamenuItemProps> = ({ title, url, imageUrl }) => (
  <div className="grid__item">
    <Link internal href={url} title={title} className="grid__item-link">
      <div className="image">
        {imageUrl && (
          <img
            src={setImageParameters(imageUrl, { width: 180, height: 180, mode: "crop" })}
            alt="Destination"
            className="image__content"
          />
        )}
      </div>
      <div className="grid__item-copy">
        <div className="destination-label">
          <Icon name="ui-marker" />
          {title}
        </div>
      </div>
    </Link>
  </div>
);

export default MegamenuItem;
