import React, { MouseEventHandler } from "react";
import Link from "../link";

interface TypeAheadOptionProps {
  id: string;
  href?: string;
  title?: string;
  onClick?: (id: string) => void;
}

const TypeaheadOption: React.FC<TypeAheadOptionProps> = (props) => {
  const handleClick: MouseEventHandler<HTMLAnchorElement> = (event) => {
    if (props.onClick) {
      event.preventDefault();
      props.onClick(props.id);
    }
  };

  return (
    <Link
      internal
      href={typeof props.onClick === "undefined" ? props.href : undefined}
      title={props.title}
      className="typeahead__option typeahead__option--icon"
      onClick={handleClick}
    >
      {props.children}
    </Link>
  );
};

export default TypeaheadOption;
