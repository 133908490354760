/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import { useI18next } from "gatsby-plugin-react-i18next";
import { trimEnd } from "lodash";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet";

const Layout: React.FC = ({ children }) => {
  const { language } = useI18next();
  const cookieconsentLanguage = language.substring(0, 2);
  const handleScriptLoad = () => {
    cookieconsent.run({
      notice_banner_type: "headline",
      consent_type: "express",
      palette: "light",
      language: cookieconsentLanguage,
      website_name: "Travelworld",
      change_preferences_selector: "#changePreferences",
      cookies_policy_url: `${trimEnd(process.env.SITE_URL, " / ")}/privacy`,
    });
  };

  useEffect(() => {
    handleScriptLoad();
  });

  return (
    <div className="page">
      <Helmet>
        <link href="https://use.typekit.net/olb7mgr.css" rel="stylesheet" />
        <link href="https://fonts.googleapis.com/css2?family=Prata&display=swap" rel="stylesheet" />
      </Helmet>
      {children}
    </div>
  );
};

export default Layout;
