import { graphql } from "gatsby";
import React from "react";
import { TideItemForHotelConnection, TideItemForRoundtripConnection } from "../../types";
import Icon from "../icon";
import { LookupData } from "../lookup-context/types";
import TypeaheadOptions from "./typeahead-options";
import { Trans, useI18next } from "gatsby-plugin-react-i18next";

interface QSMProps {
  searchTerm?: string;
  results?: LookupData[];
  onSearchTermChange?: (searchTerm: string) => void;
  onClear?: () => void;
  onClose?: () => void;
}

const QSM: React.FC<QSMProps> = ({
  searchTerm = "",
  results = [],
  onSearchTermChange,
  onClear,
  onClose,
}) => {
  const handleClose: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault();

    if (onClear) {
      onClear();
    }

    if (onClose) {
      onClose();
    }
  };

  const handleSearchTermChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    if (onSearchTermChange) {
      onSearchTermChange(e.target.value);
    }
  };

  const { t } = useI18next();

  return (
    <div className="qsm">
      <form className="form form--qsm form--qsm-navigation">
        <div className="form__row">
          <div className="form__group">
            <div className="qsm__trigger">
              <input
                type="text"
                className="form__input"
                value={searchTerm}
                onChange={handleSearchTermChange}
                placeholder={t("QSM_PLACEHOLDER")}
              />
              <Icon name="ui-search" />
            </div>
            <div className="qsm__panel">
              <div className="qsm__panel-header">
                <h4 className="qsm__panel-heading">
                  <Trans>QSM_PLACEHOLDER</Trans>
                </h4>
                <button
                  type="button"
                  className="button button--close"
                  onClick={handleClose}
                ></button>
              </div>
              <div className="qsm__panel-body">
                <div className="typeahead">
                  <div className="typeahead__trigger">
                    <input
                      type="text"
                      className="typeahead__input"
                      value={searchTerm}
                      onChange={handleSearchTermChange}
                      placeholder={t("QSM_PLACEHOLDER")}
                      required
                    />
                    <button
                      type="button"
                      className="typeahead__clear-button"
                      onClick={handleClose}
                      title={t("DELETE")}
                    ></button>
                  </div>
                  {searchTerm.length > 2 && results.length > 0 && (
                    <TypeaheadOptions searchTerm={searchTerm} results={results} />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default QSM;

export interface LookupQueryData {
  hotelLookupData: TideItemForHotelConnection;
  roundtripLookupData: TideItemForRoundtripConnection;
}

export const query = graphql`
  fragment lookupQueryFragment on Query {
    hotelLookupData: allTideItemForHotel {
      nodes {
        ...lookupQueryNodeFragment
      }
    }
    roundtripLookupData: allTideItemForRoundtrip {
      nodes {
        ...lookupQueryNodeFragment
      }
    }
  }

  fragment lookupQueryNodeFragment on TideItem {
    id
    name
    templateName
    ... on TideItemForHotel {
      content {
        general {
          title
          slug
          product {
            tideId
            productType
          }
        }
      }
      ...productPathFragment
    }
    ... on TideItemForRoundtrip {
      content {
        general {
          title
          slug
          product {
            tideId
            productType
          }
        }
      }
      ...productPathFragment
    }
    parentItem {
      id
      templateName
      ... on TideItemForCountry {
        name
        content {
          general {
            title
            slug
            country {
              tideId
            }
          }
        }
      }
      ... on TideItemForRegion {
        name
        content {
          general {
            title
            slug
            region {
              tideId
            }
          }
        }
      }
      parentItem {
        id
        templateName
        ... on TideItemForCountry {
          id
          templateName
          parentItem {
            id
            templateName
            ... on TideItemForGeographicalRegion {
              name
              content {
                general {
                  title
                }
              }
            }
          }
        }
        ... on TideItemForGeographicalRegion {
          name
          content {
            general {
              title
            }
          }
        }
      }
    }
  }
`;
