import React from "react";
import MegamenuItem from "./megamenu-item";

interface MegamenuContentProps {
  title?: string;
  items?: {
    id?: string;
    url?: string;
    imageUrl?: string;
    title?: string;
  }[];
}

const MegamenuContent: React.FC<MegamenuContentProps> = ({ title, items }) => (
  <div className="megamenu__content">
    <span className="megamenu__content-heading">{title}</span>
    <div className="grid">
      <div className="grid__items">
        {(items ?? [])?.map((item) => (
          <MegamenuItem key={item.id} title={item.title} imageUrl={item.imageUrl} url={item.url} />
        ))}
      </div>
    </div>
  </div>
);

export default MegamenuContent;
