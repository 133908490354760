import {
  TideItemForCountry,
  TideItemForGeographicalRegion,
  TideItemForHotel,
  TideItemForRegion,
  TideItemForRoundtrip,
} from "../../types";
import { generateCountryOrRegionPath, generateProductPath, generateRegionPath } from "../../utils";
import { compact, isEmpty, uniqBy } from "lodash";

import { LookupData } from "./types";
import { LookupQueryData } from "../qsm";

const getDisplayName = (
  node:
    | TideItemForRoundtrip
    | TideItemForHotel
    | TideItemForCountry
    | TideItemForRegion
    | TideItemForGeographicalRegion
) => (isEmpty(node.content?.general?.title) ? node.name : node.content?.general?.title) ?? "";

const mapProductLookupData = (
  productNodes: (TideItemForHotel | TideItemForRoundtrip)[]
): LookupData[] =>
  compact(
    productNodes.map((productNode) => {
      const regionNode =
        productNode.parentItem?.templateName == "Region"
          ? (productNode.parentItem as TideItemForRegion)
          : undefined;
      const countryNode = regionNode
        ? (regionNode.parentItem as TideItemForCountry)
        : (productNode.parentItem as TideItemForCountry);

      if (!countryNode) return null;
      const geographicalRegionNode = countryNode.parentItem as TideItemForGeographicalRegion;

      return {
        id: productNode.id ?? "",
        itemId: productNode.content?.general?.product?.tideId,
        name: getDisplayName(productNode),
        url: generateProductPath(productNode) ?? "",
        country: getDisplayName(countryNode),
        region: regionNode ? getDisplayName(regionNode) : "",
        geographicalRegion: getDisplayName(geographicalRegionNode),
        templateName: productNode.templateName as "Hotel" | "Roundtrip",
        productType: productNode.content?.general?.product?.productType ?? undefined,
      };
    })
  );

const mapCountryLookupData = (
  productNodes: (TideItemForHotel | TideItemForRoundtrip)[]
): LookupData[] =>
  uniqBy(
    compact(
      productNodes.flatMap((productNode) =>
        productNode?.parentItem?.templateName === "Region"
          ? (productNode.parentItem?.parentItem as TideItemForCountry)
          : (productNode.parentItem as TideItemForCountry)
      )
    ),
    (countryNode) => countryNode.id
  ).map((countryNode) => {
    const country = countryNode.content?.general?.country;
    const geographicalRegionNode = countryNode.parentItem as TideItemForGeographicalRegion;

    return {
      id: countryNode.id ?? "",
      itemId: country?.tideId,
      name: getDisplayName(countryNode),
      geographicalRegion: getDisplayName(geographicalRegionNode),
      url: generateCountryOrRegionPath(countryNode) ?? "",
      templateName: countryNode.templateName as "Country",
    };
  });

const mapRegionLookupData = (
  productNodes: (TideItemForHotel | TideItemForRoundtrip)[]
): LookupData[] =>
  uniqBy(
    productNodes
      .filter((x) => x.parentItem?.templateName === "Region")
      .flatMap((productNode) => productNode.parentItem as TideItemForRegion),
    (countryNode) => countryNode.id
  ).map((regionNode) => {
    const region = regionNode.content?.general?.region;
    const geographicalRegionNode = regionNode.parentItem
      ?.parentItem as TideItemForGeographicalRegion;
    const country = regionNode.parentItem as TideItemForCountry;

    return {
      id: regionNode.id ?? "",
      itemId: region?.tideId,
      name: getDisplayName(regionNode),
      country: getDisplayName(country),
      geographicalRegion: getDisplayName(geographicalRegionNode),
      url: generateRegionPath(regionNode) ?? "",
      templateName: regionNode.templateName as "Region",
    };
  });

let lookupDataPromises: { [language: string]: Promise<LookupData[]> } = {};
export async function fetchLookupData(language: string): Promise<LookupData[]> {
  if (lookupDataPromises[language]) return lookupDataPromises[language];

  lookupDataPromises[language] = new Promise(async (resolve) => {
    const response = await fetch(`/${language}/feed.json`);
    const json = (await response.json()) as LookupQueryData;

    const hotelNodes = json.hotelLookupData.nodes;
    const roundtripNodes = json.roundtripLookupData.nodes;

    resolve([
      ...mapProductLookupData(hotelNodes),
      ...mapProductLookupData(roundtripNodes),
      ...mapCountryLookupData([...hotelNodes, ...roundtripNodes]),
      ...mapRegionLookupData([...hotelNodes, ...roundtripNodes]),
    ]);
  });

  return lookupDataPromises[language];
}
