import React from "react";

interface HighlightTextProps {
  text: string;
  highlight?: string;
}

const HighlightText: React.FC<HighlightTextProps> = ({ text, highlight = "" }) => {
  const matchIndex = text.toLowerCase().indexOf(highlight.toLowerCase());

  return matchIndex > -1 ? (
    <>
      {text.slice(0, matchIndex)}
      <em className="typeahead__option-highlight">
        {text.slice(matchIndex, matchIndex + highlight.length)}
      </em>
      {text.slice(matchIndex + highlight.length)}
    </>
  ) : (
    <>{text}</>
  );
};

export default HighlightText;
