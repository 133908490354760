import React, { AnchorHTMLAttributes, useContext } from "react";
// import { Link as GatsbyLink } from "gatsby";
import { omit, trim } from "lodash";
import { Link as GatsbyLink, useI18next } from "gatsby-plugin-react-i18next";

interface LinkProps extends AnchorHTMLAttributes<HTMLAnchorElement> {
  href?: string;
  internal?: boolean;
  className?: string;
  title?: string;
  target?: string;
  onClick?: React.MouseEventHandler<HTMLAnchorElement>;
}

const Link: React.FC<LinkProps> = ({ internal, href, children, ...props }) => {
  const linkProps = omit(props, ["href", "children", "internal"]);
  const { language } = useI18next();

  return internal && typeof href !== "undefined" ? (
    <GatsbyLink language={language} nonce={undefined} to={href} {...linkProps}>
      {children}
    </GatsbyLink>
  ) : (
    <a href={href} {...linkProps}>
      {children}
    </a>
  );
};

export default Link;
