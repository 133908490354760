import {
  TideClientConfig,
  confirm,
  login,
  forgotPassword,
  logout,
  resetPassword,
  getEntryList,
  createParams,
  getCountries,
  print,
  getCustomEntryStatus,
  getEntryStatus,
  getInvoiceList,
} from "@qite/tide-client";
import {
  MemberConfirmationRequest,
  MemberLoginRequest,
  MemberForgotPasswordRequest,
  MemberResetPasswordRequest,
  MemberLoginResponse,
  FilterItem,
  DossierViewResult,
  PageResult,
  CustomEntryStatusItem,
  Column,
  NumberStringPair,
  AgentPrintActionRequest,
  AgentInvoiceItem,
} from "@qite/tide-client/build/types";
import { endpointContact, endpointCrmcontact, endpointOffer } from "./constants/tide-endpoint";
import { addYears } from "date-fns";
export const officeId = 1;
export const webAgentId = 10301;
export const customWebOfferStatusId = 6;

const HOST = process.env.TIDE_HOST;
const API_KEY = process.env.API_KEY;

interface TideCrmcontactRequest {
  firstName: string;
  lastName: string;
  gender?: number;
  email: string;
  tags?: number[];
  phone?: string;
  remark?: string;
  contactMessage?: string;
  avoidCreatingCrmContact?: boolean;
  languageCode?: string;
  confirmationEmailTemplate?: number;
  confirmationEmailBody?: string;
  tourGroupPreference?: { adults: number; children: number; rooms: number };
}

interface TideContactRequest {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  subject: string;
  message: string;
}

interface Traveler {
  id: number;
  guid: string;
  age?: number;
  firstName?: string;
  lastName?: string;
}

interface CustomerRequest {
  id: number;
  title: string;
  message: string;
}

interface Room {
  accommodationCode?: string;
  regimeCode?: string;
  travelers: Traveler[];
  customerRequests?: CustomerRequest[];
}

interface TideOfferRequest {
  officeId: number;
  agentId?: number;
  customEntryStatusId?: number;
  productCode?: string;
  startDate?: string;
  endDate?: string;
  address: {
    email: string;
    mobilePhone: string;
    street?: string;
    country?: string;
    location?: string;
  };
  rooms: Room[];
}

export async function requestCrmcontact(request: TideCrmcontactRequest): Promise<Response> {
  const response = await fetch(endpointCrmcontact, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Api-Key": "ca028e2f-217a-4e4a-8e7e-8d556be17de3",
    },
    body: JSON.stringify(request),
  });

  if (!response.ok) {
    throw new Error(response.statusText);
  }

  return response;
}

export async function requestContact(request: TideContactRequest): Promise<Response> {
  const response = await fetch(endpointContact, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Api-Key": "ca028e2f-217a-4e4a-8e7e-8d556be17de3",
    },
    body: JSON.stringify(request),
  });

  if (!response.ok) {
    throw new Error(response.statusText);
  }

  return response;
}

export async function createTideOffer(request: TideOfferRequest): Promise<Response> {
  const response = await fetch(endpointOffer, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Api-Key": "ca028e2f-217a-4e4a-8e7e-8d556be17de3",
    },
    body: JSON.stringify(request),
  });

  if (!response.ok) {
    throw new Error(response.statusText);
  }

  return response;
}

export const confirmMember = async (token: string, password: string, hasAgreed: boolean) => {
  if (!HOST || !API_KEY) {
    return Promise.reject();
  }

  const tideClientConfig: TideClientConfig = {
    host: HOST,
    apiKey: API_KEY,
  };

  const memberConfirmationRequest: MemberConfirmationRequest = {
    token: token,
    password: password,
    hasAgreed: hasAgreed,
  };

  const response = await confirm(tideClientConfig, memberConfirmationRequest, undefined);
  return response;
};

export const loginMember = async (
  username: string,
  password: string
): Promise<MemberLoginResponse> => {
  if (!HOST || !API_KEY) {
    return Promise.reject();
  }

  const tideClientConfig: TideClientConfig = {
    host: HOST,
    apiKey: API_KEY,
  };

  const memberLogingRequest: MemberLoginRequest = {
    username: username,
    password: password,
  };

  const memberLoginResponse = await login(tideClientConfig, memberLogingRequest, undefined);
  return memberLoginResponse;
};

export const logoutMember = async () => {
  if (!HOST || !API_KEY) {
    return Promise.reject();
  }

  const tideClientConfig: TideClientConfig = {
    host: HOST,
    apiKey: API_KEY,
  };

  const response = await logout(tideClientConfig, undefined);
  return response.ok;
};

export const passwordForgotten = async (username: string) => {
  if (!HOST || !API_KEY) {
    return Promise.reject();
  }

  const tideClientConfig: TideClientConfig = {
    host: HOST,
    apiKey: API_KEY,
  };

  const memberForgotPasswordRequest: MemberForgotPasswordRequest = {
    username: username,
  };

  const response = await forgotPassword(tideClientConfig, memberForgotPasswordRequest, undefined);
  return response.ok;
};

export const newPassword = async (token: string, password: string) => {
  if (!HOST || !API_KEY) {
    return Promise.reject();
  }

  const tideClientConfig: TideClientConfig = {
    host: HOST,
    apiKey: API_KEY,
  };

  const memberResetPasswordRequest: MemberResetPasswordRequest = {
    token: token,
    password: password,
  };

  const response = await resetPassword(tideClientConfig, memberResetPasswordRequest, undefined);
  return response.ok;
};

export const getDossier = async (
  filterItem: FilterItem,
  token: string,
  signal: AbortSignal,
  gridColumns?: Column[] | undefined
): Promise<PageResult<DossierViewResult>> => {
  if (!HOST || !API_KEY) {
    return Promise.reject();
  }

  const tideClientConfig: TideClientConfig = {
    host: HOST,
    apiKey: API_KEY,
    token: token,
  };

  const response = await getEntryList(tideClientConfig, filterItem, gridColumns, signal);
  return response;
};

export const getInvoices = async (
  filterItem: FilterItem,
  token: string,
  signal: AbortSignal,
  gridColumns?: Column[] | undefined
): Promise<PageResult<AgentInvoiceItem>> => {
  if (!HOST || !API_KEY) {
    return Promise.reject();
  }

  const tideClientConfig: TideClientConfig = {
    host: HOST,
    apiKey: API_KEY,
    token: token,
  };

  const response = await getInvoiceList(tideClientConfig, filterItem, gridColumns, signal);
  return response;
};

export const getEntryStatusList = async (): Promise<NumberStringPair[]> => {
  if (!HOST || !API_KEY) {
    return Promise.reject();
  }

  const tideClientConfig: TideClientConfig = {
    host: HOST,
    apiKey: API_KEY,
  };

  const response = await getEntryStatus(tideClientConfig);
  return response;
};

export const getCustomEntryStatusList = async (): Promise<CustomEntryStatusItem[]> => {
  if (!HOST || !API_KEY) {
    return Promise.reject();
  }

  const tideClientConfig: TideClientConfig = {
    host: HOST,
    apiKey: API_KEY,
  };

  const response = await getCustomEntryStatus(tideClientConfig);
  return response;
};

export const getCountryList = async (signal?: AbortSignal) => {
  if (!HOST || !API_KEY) {
    return Promise.reject();
  }

  const tideClientConfig: TideClientConfig = {
    host: HOST,
    apiKey: API_KEY,
  };

  const response = await getCountries(tideClientConfig, signal);
  return response;
};

export const printAction = async (
  request: AgentPrintActionRequest,
  token: string,
  signal?: AbortSignal
): Promise<Blob> => {
  if (!HOST || !API_KEY) {
    return Promise.reject();
  }

  const tideClientConfig: TideClientConfig = {
    host: HOST,
    apiKey: API_KEY,
    token: token,
  };

  const response = await print(tideClientConfig, request, signal);

  if (!response.ok) {
    throw new Error(response.statusText);
  }

  const body = await response.blob();

  return body;
};
