import React, { MouseEventHandler } from "react";
import { buildClassName } from "../../utils";
import Link from "../link";
import MegamenuContent from "./megamenu-content";

interface MegamenuGroupProps {
  active?: boolean;
  title?: string;
  longTitle?: string;
  url?: string;
  items?: {
    url?: string;
    imageUrl?: string;
    title?: string;
  }[];
  onMouseEnter: () => void;
}

const MegamenuGroup: React.FC<MegamenuGroupProps> = ({
  active,
  title,
  longTitle,
  items,
  onMouseEnter,
}) => (
  <div
    className={buildClassName([
      "megamenu__sidebar-item",
      active && "megamenu__sidebar-item--active",
    ])}
    onMouseEnter={() => onMouseEnter()}
  >
    <div className="megamenu__sidebar-link">{title}</div>
    <MegamenuContent title={longTitle} items={items} />
  </div>
);

export default MegamenuGroup;
