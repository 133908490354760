import { isEmpty } from "lodash";
import React from "react";
import {
  TideItem,
  TideItemForCountry,
  TideItemForDestinations,
  TideItemForGeographicalRegion,
  TideItemForRegion,
  TideItemForRoundtrip,
} from "../../types";
import { generatePath } from "../../utils";
import Megamenu from "../megamenu";
import { useI18next } from "gatsby-plugin-react-i18next";

interface RoundtripsMegamenuProps {
  destinations: TideItemForDestinations;
}

const RoundtripsMegamenu: React.FC<RoundtripsMegamenuProps> = ({ destinations }) => {
  const geographicalRegions = destinations?.childItems?.filter(
    (tideItem) => tideItem?.templateName === "Geographical region"
  ) as TideItemForGeographicalRegion[];
  const { t } = useI18next();

  const roundTripFilter = (tideItem: TideItem | null | undefined) =>
    tideItem?.templateName === "Roundtrip" ||
    (tideItem?.templateName === "Region" &&
      tideItem?.childItems?.some((y) => y?.templateName === "Roundtrip"));

  const roundtripGroups = geographicalRegions
    .filter(
      (geographicalRegion) =>
        (geographicalRegion.childItems ?? [])
          .flatMap((tideItem) => tideItem?.childItems)
          .filter(roundTripFilter).length > 0
    )
    .map((geographicalRegion) => ({
      id: geographicalRegion.id,
      title: !isEmpty(geographicalRegion.content?.general?.title)
        ? (geographicalRegion.content?.general?.title as string)
        : geographicalRegion.name,
      longTitle:
        `${t("ROUNDTRIPS")} ` + geographicalRegion.content?.general?.title ??
        geographicalRegion.name,
      items: ((geographicalRegion.childItems as (TideItemForCountry | TideItemForRegion)[]) ?? [])
        .filter((tideItem) => (tideItem?.childItems ?? [])?.filter(roundTripFilter).length > 0)
        .map((countryOrRegion) => {
          var url = generatePath(countryOrRegion, generatePath(destinations));

          return {
            id: countryOrRegion?.id ?? undefined,
            url: url ? `${url}#roundtrips` : undefined,
            imageUrl: countryOrRegion.content?.general?.thumbnailPicture?.url ?? undefined,
            title: countryOrRegion.content?.general?.title ?? undefined,
          };
        }),
    }));

  return <Megamenu groups={roundtripGroups} />;
};

export default RoundtripsMegamenu;
