import React from "react";
import Icon from "../icon";
import { getChildNavigationLinks } from "../../utils";
import Link from "../link";
import { TideItemForNavigationSection } from "../../types";

interface SocialProps {
  socialNavigationSection: TideItemForNavigationSection;
}

const Social: React.FC<SocialProps> = ({ socialNavigationSection }) => (
  <ul className="list list--inline list--socials">
    {getChildNavigationLinks(socialNavigationSection).map((navigationLink) => {
      const generalContent = navigationLink.content?.general;

      return (
        <li className="list__item" key={navigationLink.id}>
          <Link
            href={generalContent?.url ?? ""}
            title={generalContent?.title ?? undefined}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Icon name={generalContent?.icon ?? ""} />
          </Link>
        </li>
      );
    })}
  </ul>
);

export default Social;
