import { isEmpty } from "lodash";
import React from "react";
import { TideItemForNavigationSection } from "../types";
import { getHrefFromNavigationLink, getChildNavigationLinks } from "../utils";
import Link from "./link";
import { useI18next } from "gatsby-plugin-react-i18next";

interface LegalProps {
  legalNavigationSection: TideItemForNavigationSection;
  showCookiePreferencesLink?: boolean;
}

const Legal: React.FC<LegalProps> = ({ legalNavigationSection, showCookiePreferencesLink }) => {
  const year = new Date().getFullYear();
  const { t } = useI18next();

  return (
    <div className="legal">
      {getChildNavigationLinks(legalNavigationSection).map((navigationLink) => (
        <div className="legal__item" key={navigationLink.id}>
          <Link
            key={navigationLink.id}
            href={getHrefFromNavigationLink(navigationLink)}
            internal={!isEmpty(navigationLink.content?.general?.path)}
            title={navigationLink?.content?.general?.title ?? undefined}
            className="link link--plain"
          >
            {navigationLink?.content?.general?.title}
          </Link>
        </div>
      ))}
      {showCookiePreferencesLink && (
        <div className="legal__item">
          <a id="changePreferences" className="link link--plain">
            {t("UPDATE_COOKIE_PREFERENCES")}
          </a>
        </div>
      )}
      <div className="legal__item">&copy; {year}, Travelworld</div>
    </div>
  );
};

export default Legal;
