import { useI18next } from "gatsby-plugin-react-i18next";
import React from "react";
import { AlternateRoute } from "../../.gatsby/gatsby-node";

interface LanguageSelectorProps {
  alternateRoutes?: AlternateRoute[];
}

const LanguageSelector: React.FC<LanguageSelectorProps> = (props) => {
  const { alternateRoutes } = props;

  const languageSelected: React.FormEventHandler<HTMLSelectElement> = async (e) => {
    const language = e.currentTarget.value;
    const alternateRoute = alternateRoutes?.find((x) => x.language == language)?.path;

    await changeLanguage(language, alternateRoute || originalPath);
  };

  const { languages, changeLanguage, language, originalPath } = useI18next();
  const languagesWithName = languages.map((language) => ({
    language,
    name: language.substring(0, 2),
  }));

  return (
    <div className="language-selector dropdown">
      <select onChange={languageSelected} value={language}>
        {languagesWithName.map((languageWithName) => (
          <option key={languageWithName.language} value={languageWithName.language}>
            {languageWithName.name}
          </option>
        ))}
      </select>
    </div>
  );
};

export default LanguageSelector;
