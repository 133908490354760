import { compact } from "lodash";
import React from "react";
import Icon from "../icon";
import { LookupData } from "../lookup-context/types";
import HighlightText from "./highlight-text";
import TypeaheadOption from "./typeahead-option";
import { useI18next } from "gatsby-plugin-react-i18next";

interface TypeAheadOptionsProps {
  searchTerm?: string;
  results?: LookupData[];
  onSelect?: (id: string) => void;
}

const TypeaheadOptions: React.FC<TypeAheadOptionsProps> = ({
  searchTerm,
  results = [],
  onSelect,
}) => {
  const hotels = results.filter((x) => x.templateName === "Hotel");
  const roundtrips = results.filter((x) => x.templateName === "Roundtrip");
  const countriesAndRegions = results.filter(
    (x) => x.templateName === "Country" || x.templateName === "Region"
  );
  const { t } = useI18next();

  return (
    <div className="typeahead__options">
      <div className="typeahead__options-body">
        {countriesAndRegions.length > 0 && (
          <div className="typeahead__region">
            {countriesAndRegions.map((countryOrRegion) => (
              <TypeaheadOption
                key={countryOrRegion.id}
                id={countryOrRegion.id}
                href={countryOrRegion.url}
                title={t("SHOW_DESTINATION")}
                onClick={onSelect}
              >
                <Icon name="ui-search" />
                <div className="typeahead__option-header">
                  <span className="typeahead__option-heading">
                    <HighlightText text={countryOrRegion.name} highlight={searchTerm} />
                  </span>
                </div>
                <div className="typeahead__option-description">
                  {compact([
                    countryOrRegion.country && countryOrRegion.country,
                    countryOrRegion.geographicalRegion,
                  ]).join(", ")}
                </div>
              </TypeaheadOption>
            ))}
          </div>
        )}
        {hotels.length > 0 && (
          <div className="typeahead__region">
            {hotels.map((hotel) => (
              <TypeaheadOption
                key={hotel.id}
                id={hotel.id}
                href={hotel.url}
                title={t("SHOW_HOTEL")}
                onClick={onSelect}
              >
                <Icon name="ui-bed" />
                <div className="typeahead__option-header">
                  <span className="typeahead__option-heading">
                    <HighlightText text={hotel.name} highlight={searchTerm} />
                  </span>
                </div>
                <div className="typeahead__option-description">
                  {compact([
                    hotel.region && hotel.region,
                    hotel.country && hotel.country,
                    hotel.geographicalRegion,
                  ]).join(", ")}
                </div>
              </TypeaheadOption>
            ))}
          </div>
        )}
        {roundtrips.length > 0 && (
          <div className="typeahead__region">
            {roundtrips.map((roundtrip) => (
              <TypeaheadOption
                key={roundtrip.id}
                id={roundtrip.id}
                href={roundtrip.url}
                title={t("SHOW_ROUNDTRIP")}
                onClick={onSelect}
              >
                <Icon name="ui-route" />
                <div className="typeahead__option-header">
                  <span className="typeahead__option-heading">
                    <HighlightText text={roundtrip.name} highlight={searchTerm} />
                  </span>
                </div>
              </TypeaheadOption>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default TypeaheadOptions;
