import React, { useState } from "react";
import MegamenuGroup from "./megamenu-group";

interface MegamenuProps {
  groups?: {
    id?: string;
    title?: string;
    longTitle?: string;
    items?: {
      id?: string;
      url?: string;
      imageUrl?: string;
      title?: string;
    }[];
  }[];
}

const Megamenu: React.FC<MegamenuProps> = ({ groups }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  return (
    <section className="megamenu">
      <div className="megamenu__container">
        <div className="megamenu__sidebar">
          {(groups ?? []).map((group, index) => (
            <MegamenuGroup
              key={group.id}
              active={index === activeIndex}
              title={group.title}
              longTitle={group.longTitle}
              items={group.items}
              onMouseEnter={() => setActiveIndex(index)}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default Megamenu;
