import React, { useContext, useEffect, useState } from "react";
import { buildClassName } from "../../utils";
import Icon from "../icon";
import QSM from "../qsm";
import { filter } from "../lookup-context/utils";
import { LookupData } from "../lookup-context/types";
import { fetchLookupData } from "../lookup-context/mapping";
import { useI18next } from "gatsby-plugin-react-i18next";

interface NavigationQSMProps {}

const NavigationQSM: React.FC<NavigationQSMProps> = () => {
  const { language } = useI18next();
  const [searchTerm, setSearchTerm] = useState("");
  const [isVisibleOnMobile, setVisibleOnMobile] = useState<boolean>(false);
  const [lookupData, setLookupData] = useState<LookupData[]>([]);

  const asyncFetchLookupData = async () => {
    const data = await fetchLookupData(language);
    setLookupData(data);
  };

  useEffect(() => {
    asyncFetchLookupData();
  }, []);

  const results = filter(searchTerm, lookupData);
  const hasResults = searchTerm.length > 2 && results.length > 0;

  return (
    <div
      className={buildClassName([
        "navigation-qsm",
        (hasResults || isVisibleOnMobile) && "navigation-qsm--active",
      ])}
    >
      <div className="navigation-qsm__trigger">
        <button
          type="button"
          className="button button--search"
          onClick={() => setVisibleOnMobile(!isVisibleOnMobile)}
        >
          <Icon name="ui-search" />
        </button>
      </div>
      <div className="navigation-qsm__form">
        <QSM
          onClear={() => setSearchTerm("")}
          onClose={() => setVisibleOnMobile(false)}
          searchTerm={searchTerm}
          onSearchTermChange={(value) => setSearchTerm(value)}
          results={results}
        />
      </div>
    </div>
  );
};

export default NavigationQSM;
