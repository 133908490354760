import React from "react";
import icons from "../images/travelworld_icons.svg";
import { buildClassName } from "../utils";

interface IconProps {
  name: string;
  className?: string;
  title?: string;
}

const Icon: React.FC<IconProps> = ({ name, className, title }) => {
  return (
    <svg className={buildClassName(["icon", `icon--${name}`, className])}>
      {title && <title>{title}</title>}
      <use href={`${icons}#${name}`}></use>
    </svg>
  );
};

export default Icon;
