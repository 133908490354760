import { graphql } from "gatsby";
import { isEmpty } from "lodash";
import React from "react";
import { TideItemForNavigationSection } from "../../types";
import { getHrefFromNavigationLink, getChildNavigationLinks } from "../../utils";
import Link from "../link";

export interface FullscreenNavigationSectionProps {
  navigationSection: TideItemForNavigationSection;
}

const FullscreenNavigationSection: React.FC<FullscreenNavigationSectionProps> = ({
  navigationSection,
}) => {
  const generalContent = navigationSection?.content?.general;
  const hasSectionLink =
    (!isEmpty(generalContent?.path) || !isEmpty(generalContent?.url)) &&
    !isEmpty(generalContent?.text);

  return (
    <div className="navigation__group">
      <h3 className="navigation__heading">{generalContent?.title}</h3>
      {getChildNavigationLinks(navigationSection).map((navigationLink) => (
        <Link
          key={navigationLink.id}
          href={getHrefFromNavigationLink(navigationLink)}
          internal={!isEmpty(navigationLink.content?.general?.path)}
          title={navigationLink?.content?.general?.title ?? undefined}
          className="link link--plain"
        >
          {navigationLink?.content?.general?.title}
        </Link>
      ))}
      {hasSectionLink && (
        <>
          <br />
          <Link
            href={
              (!isEmpty(generalContent?.path)
                ? generalContent?.path?.startsWith("/")
                  ? generalContent.path
                  : `/${generalContent?.path}`
                : generalContent?.url) ?? undefined
            }
            internal={!isEmpty(generalContent?.path)}
            title={generalContent?.text ?? undefined}
            className="link"
          >
            {generalContent?.text}
          </Link>
        </>
      )}
    </div>
  );
};

export const query = graphql`
  fragment fullscreenNavigationSectionFields on TideItemForNavigationSection {
    id
    childItems {
      id
      templateName
      ... on TideItemForNavigationSection {
        content {
          general {
            title
            text
            path
            url
          }
        }
        childItems {
          ...navigationLinkFields
        }
      }
    }
  }

  fragment navigationLinkFields on TideItemForNavigationLink {
    id
    templateName
    name
    content {
      general {
        title
        icon
        path
        url
      }
    }
  }
`;

export default FullscreenNavigationSection;
